/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "typeface-oswald"
import "typeface-eb-garamond"
import "typeface-roboto-condensed"
import "intersection-observer"

import { toast, Slide } from "react-toastify"

toast.configure({
  transition: Slide,
  closeButton: false,
})

export const shouldUpdateScroll = ({ routerProps: { location } }) =>
  !location.hash
